.items-viewer {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	outline: none;
}

.items-viewer .items-viewer__icon-button {
	height: 32px !important;
	width: 32px !important;
	padding: 0px !important;
	margin: 0px !important;
	right: 9px !important;
}

.items-viewer__ckeck {
	visibility: hidden;
	transition: none!important;
}

.items-viewer__ckeck_selected {
	visibility: visible;
}

.items-viewer__search-field {
	flex: 0 0 auto;
}

.items-viewer__items {
	flex: 1 1 auto;
}