.select label,
.input label {
	font-size: 11.5px !important;
	width: 100% !important;
	transform: translate(0px, -24px) !important;
	transition: none !important;
}

.select button {
	position: relative;
	top: 16px !important;
	padding: 4px !important;
	width: 32px !important;
	height: 32px !important;
}

.select_disabled * {
	cursor: not-allowed !important;
}

.input_readonly,
.select_readonly {
	cursor: default !important;
}

.input_readonly div,
.select_readonly div {
	cursor: default !important;
}

.input_readonly button,
.select_readonly button {
	visibility: hidden !important;
	transition: none !important;
}