.items-list-repeater {
	height: 100%;
	display: flex;
	flex-direction: row;
	position: relative;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.items-list-repeater__row {
	position: relative;
	transition: box-shadow 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	cursor: pointer;
}

.items-list-repeater__row:hover {
	box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
	z-index: 1;
}

.items-list-repeater__row_last {
	border: none;
}

.items-list-repeater__cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.items-list-repeater__checkbox_hidden {
	display: none !important;
}

.items-list-repeater__row:hover .items-list-repeater__checkbox,
.items-list-repeater__row_checked .items-list-repeater__checkbox {
	display: block !important;
}

.items-list-repeater__row:hover .items-list-repeater__icon,
.items-list-repeater__row_checked .items-list-repeater__icon {
	display: none !important;
}
