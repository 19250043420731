*,
*::after,
*::before {
	box-sizing: border-box;
}

* {
	font-family: arial, sans-serif;
	margin: 0;
	padding: 0;
	border: 0;
}

html {
	scroll-behavior: smooth;
}

body {
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;

	@media(max-width: 1200px) {
		min-width: 1200px;
		overflow-x: auto;
	}
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

#root [data-reactroot] > div {
	width: 100%;
	height: 100%;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

h1 {
	font-size: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 500;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

p {
	margin: 0 0 10px;
}

a {
	text-decoration: none;
}

i {
	font-style: normal;
}

label {
	pointer-events: auto !important;
}

input::-ms-clear {
	display: none;
}

.workspace {
	position: relative;
	width: 100%;
	min-height: 100%;
	padding: 70px 0 0 80px;
}

.avoid-page-break-inside {
	page-break-inside: avoid;
}

[data-rmiz-modal-overlay=visible] {
	background-color: rgba(62, 71, 87, 0.7) !important;
}

[data-rmiz-btn-unzoom="true"] {
	display: none !important;
}
