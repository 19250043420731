@font-face {
	font-family: 'Glober';
	src: local('Glober Thin Free'), url('../../../../../../../fonts/GloberThinFree.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Glober';
	src: local('Glober Thin Free'), url('../../../../../../../fonts/GloberRegularFree.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Glober';
	src: local('Glober Bold Free'), url('../../../../../../../fonts/GloberBoldFree.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}