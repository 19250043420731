@font-face {
	font-family: 'Graphik';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('./Graphik-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Graphik';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url('./Graphik-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Graphik';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url('./Graphik-bold.woff2') format('woff2');
}
