.table td,
.table th {
	padding: 8px;
}

.table .controls-cell {
	padding-top: 0;
	padding-bottom: 0;
}

.table .controls-row td,
.table .controls-row th {
	padding-top: 0;
	padding-bottom: 0;
}

.table.controls-table td,
.table.controls-table th {
	padding-top: 0;
	padding-bottom: 0;
}

.table .number-cell {
	white-space: nowrap;
	text-align: right;
}

.table th {
	font-weight: bold;
	text-align: left;
}

.input_type_text input {
	text-overflow: ellipsis;
}

.rdr-MonthAndYear-divider {
	display: inline-block;
	width: 6px;
	color: #fff;
}

[data-menu-autocomplete] {
	max-height: 300px !important;
	overflow-y: auto !important;
}

[data-menu-autocomplete]>div>span {
	display: flex !important;
	align-items: center !important;
	line-height: 16px !important;
	min-height: 32px !important;
}

[data-menu-autocomplete]>div>span>div {
	width: 100%;
}

[data-menu-autocomplete]>div>span>div>div {
	width: 100%;
	overflow: visible !important;
	word-wrap: break-word;
	white-space: initial;
	line-height: 16px !important;
	min-height: 32px !important;
	font-size: 14px !important;
	display: flex !important;
	align-items: center !important;
	padding: 8px 24px !important;
}