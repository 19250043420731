.items-picker {
	margin-bottom: -16px;
	margin-top: -16px;
}

.items-picker .items-viewer__elem svg {
	margin-bottom: 4px !important;
	margin-top: 4px !important;
}

.items-picker .items-viewer__search-field {
	padding: 0 15px;
	margin-top: -8px;
}

.items-select-field button {
	width: 32px !important;
	height: 32px !important;
	padding: 4px !important;
	top: 38px !important;
	fill: rgba(0, 0, 0, 0.45) !important;
}

.items-select-field>div>div>div {
	top: 28px !important;
	opacity: 1 !important;
}